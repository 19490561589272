import { createApp } from 'vue';
import ConsentMain from './ConsentMain.vue';
export var mount = function () {
    var consentAppDiv = document.getElementById('skagen-consent-app-page');
    if (consentAppDiv) {
        // This is a div on a page for updating the consent, usually on the privacy policy page
        // If the div is not found, the consent app is in a block view mode and not mounted on the skagen-consent-app-page div
        var consentInLineDiv = document.getElementById('skagen-consent-app-inline');
        var blockViewMode = consentInLineDiv ? true : false;
        var mountDivName = consentInLineDiv
            ? 'skagen-consent-app-inline'
            : 'skagen-consent-app-page';
        var app = createApp(ConsentMain, {
            blockViewMode: blockViewMode,
        });
        app.mount('#' + mountDivName);
    }
};
